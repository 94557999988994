.responsive-video {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}

.responsive-video iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

#portfolio .portfolio-wrap {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    transition: 0.3s;
}

#portfolio .portfolio-wrap:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}

#portfolio .portfolio-item {
    position: relative;
    height: 375px;
    overflow: hidden;
}

#portfolio .portfolio-item figure {
    background: #000;
    overflow: hidden;
    //height: 240px;
    position: relative;
    border-radius: 4px 4px 0 0;
    margin: 0;
}

#portfolio .portfolio-item figure:hover img {
    opacity: 0.4;
    transition: 0.3s;
}

#portfolio .portfolio-item figure .link-preview,
#portfolio .portfolio-item figure .link-details {
    position: absolute;
    display: inline-block;
    opacity: 0;
    line-height: 1;
    text-align: center;
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 50%;
    transition: 0.2s linear;
}

#portfolio .portfolio-item figure .link-preview i,
#portfolio .portfolio-item figure .link-details i {
    padding-top: 6px;
    display: inline-block;
    font-size: 22px;
    color: #333;
}

#portfolio .portfolio-item figure .link-preview:hover,
#portfolio .portfolio-item figure .link-details:hover {
    background: #149ddd;
}

#portfolio .portfolio-item figure .link-preview:hover i,
#portfolio .portfolio-item figure .link-details:hover i {
    color: #fff;
}

#portfolio .portfolio-item figure .link-preview {
    left: calc(50% - 38px);
    top: calc(50% - 18px);
}

#portfolio .portfolio-item figure .link-details {
    right: calc(50% - 38px);
    top: calc(50% - 18px);
}

#portfolio .portfolio-item figure:hover .link-preview {
    opacity: 1;
    left: calc(50% - 15px);
}

#portfolio .portfolio-item figure:hover .link-details {
    opacity: 1;
    right: calc(50% - 44px);
    z-index: 9999;
}

#portfolio .portfolio-item .portfolio-info {
    background: #fff;
    text-align: center;
    padding: 20px;
    height: 75px;
    border-radius: 0 0 3px 3px;
}

#portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    line-height: 1px;
    font-weight: 700;
    margin-bottom: 18px;
    padding-bottom: 0;
}

#portfolio .portfolio-item .portfolio-info .tags {
    font-size: 12px;
    line-height: 1px;
}

#portfolio .portfolio-item .portfolio-info h4 a {
    color: #333;
}

#portfolio .portfolio-item .portfolio-info h4 a:hover {
    color: #149ddd;
}

#portfolio .portfolio-item .portfolio-info p {
    padding: 0;
    margin: 0;
    color: #b8b8b8;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
}